import * as yup from "yup"

const Tutorschema = yup
    .object({
        firstName: yup.string().trim().matches(/^(?!\s*$).+/, "Whitespaces not allowed").required(),
        lastName:yup.string().optional(),
        mobile: yup.string().matches(/^[0-9]+$/, "Mobile number must contain only digits").optional(),
        email: yup.string().email('invalid email').required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid mail format"),
        course: yup.array().min(1).required(),   
        password:yup.string().min(8).required(),        
       // designation: yup.string().required(),
        // language: yup.string().required(),
        about: yup.string().optional()
    })
    .required()

export default Tutorschema