import * as yup from "yup"

const CourseSchema = yup
    .object({
        title: yup.string().required("Course Name is required").trim().matches(/^(?!\s*$).+/, "Whitespaces not allowed"),
        description:yup.string().required().trim().matches(/^(?!\s*$).+/, "Whitespaces not allowed"),
        overview: yup.string().required().trim().matches(/^(?!\s*$).+/, "Whitespaces not allowed"),
        level: yup.string().required(),
        requirements: yup.string().required().trim().matches(/^(?!\s*$).+/, "Whitespaces not allowed"),   
        categoryId:yup.string().required(),        
        price: yup.string().matches(/^[0-9]+$/, "Only number allowed").required()
    })
    .required()

export default CourseSchema