import { useMutation, useQuery } from '@tanstack/react-query'
import { UpdateSmtpDetails, changePaswd, editProfile, getCompanyDetails, getSmtpDetails, getUserprofile, updateCompanyDetails } from '../service/profile/profileService'
import { useAuth } from '../context/AuthContext'
const ProfileApis = {
    GetUserdetails: () => {
        const response = useQuery({
            queryKey: ["get-user-profile"],
            queryFn: () => getUserprofile()
        })
        return response
    },
    Editprofile: () => {
        const response = useMutation({
            mutationFn: (data) => editProfile(data)
        })
        return response
    },
    ChangePswd: () => {
        const response = useMutation({
            mutationFn: (data) => changePaswd(data)
        })
        return response
    },
    GetCompanyDetails:()=>{
        const {userType}=useAuth()
        const response = useQuery({
            queryKey: ["get-company-details"],
            queryFn: () => getCompanyDetails(),
            enabled:userType==="admin"?true:false
        })
        return response
    },
    UpdateCompanydetails:()=>{
        const response = useMutation({
            mutationFn: (data) => updateCompanyDetails(data)
        })
        return response
    },
    GetSmtpDetails:()=>{
        const {userType}=useAuth()
        const response = useQuery({
            queryKey: ["get-smtp-details"],
            queryFn: () => getSmtpDetails(),
            enabled:userType==="admin"?true:false

        })
        return response
    },
    UpdateSmtpDetails:()=>{
        const response = useMutation({
            mutationFn: (data) => UpdateSmtpDetails(data)
        })
        return response
    }


}
export default ProfileApis