import React from 'react'
import EditProfile from './EditProfile'
import ChangePassword from './ChangePassword'
import NotificationSettings from './NotificationSettings'
import CompanyDetails from './CompanyDetails'
import SMTPDetails from './SMTPDetails'
import { useAuth } from '../../context/AuthContext'
function ProfileSettings({ profileData, getProfiledata, Companydetails, getCompanyDetails, smtpDetails, getSmtpDetails }) {
    const { userType } = useAuth()
    return (
        <div className="col-lg-8">
            <div className="card h-100">
                <div className="card-body p-24">
                    <ul className="nav border-gradient-tab nav-pills mb-20 d-inline-flex" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link d-flex align-items-center px-24 active"
                                id="pills-edit-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-edit-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-edit-profile"
                                aria-selected="true"
                            >
                                Edit Profile
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link d-flex align-items-center px-24"
                                id="pills-change-passwork-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-change-passwork"
                                type="button"
                                role="tab"
                                aria-controls="pills-change-passwork"
                                aria-selected="false"
                                tabIndex="-1"
                            >
                                Change Password
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button
                                className="nav-link d-flex align-items-center px-24"
                                id="pills-notification-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-notification"
                                type="button"
                                role="tab"
                                aria-controls="pills-notification"
                                aria-selected="false"
                                tabIndex="-1"
                            >
                                Notification Settings
                            </button>
                        </li> */}
                        {
                            userType === "admin" &&
                            <>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link d-flex align-items-center px-24"
                                        id="pills-company-details-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-company-details"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-company-details"
                                        aria-selected="false"
                                        tabIndex="-1"
                                    >
                                        Company Details
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link d-flex align-items-center px-24"
                                        id="pills-smtp-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-smtp-content"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-smtp-content"
                                        aria-selected="false"
                                        tabIndex="-1"
                                    >
                                        SMTP Details
                                    </button>
                                </li>
                            </>
                        }


                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        {
                            profileData && <EditProfile profileData={profileData} getProfiledata={getProfiledata} />
                        }

                        <ChangePassword />
                        {/* <NotificationSettings /> */}
                        {
                            Companydetails && <CompanyDetails Companydetails={Companydetails} getCompanyDetails={getCompanyDetails} />
                        }
                        {
                            smtpDetails && <SMTPDetails SMTPDetails={smtpDetails} getSmtpDetails={getSmtpDetails} />
                        }


                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProfileSettings