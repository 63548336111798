import * as yup from "yup"

const StudentAddschema = yup
    .object({
        firstName: yup.string().required(),
        lastName:yup.string().optional(),
        mobile: yup.string().matches(/^[0-9]+$/, "Mobile number must contain only digits").optional(),
        email: yup.string().email('invalid email').required().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid mail format"),  
        password:yup.string().trim().matches(/^(?!\s*$).+/, "Whitespaces not allowed").min(8).required(),        
        about: yup.string().optional()
    })
    .required()

export default StudentAddschema